import React, { useEffect, useState, useContext } from "react";
import { Link } from "gatsby";
import GeneralButton from "../generalButton/generalButton.component";
import MobileMenu from "../mobile-menu/mobile-menu.component";
import Calculator from "../../assets/calculator.inline.svg";
import Files from "../../assets/files.inline.svg";
import Profile from "../../assets/profile.inline.svg";
import NewWindow from "../../assets/new_window.inline.svg";
import { MenuContext } from "../../context";
import { GENERAL_LINK_SCHEME } from "./header.utils";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let curScroll;
    let prevScroll = window.scrollY || window.scrollTop;
    let curDirection = 0;
    let prevDirection = 0;
    let header = document.querySelector("header");
    // Activate the hide function on this point, px.
    const threshold = 200;

    const checkScroll = () => {
      curScroll = window.scrollY || window.scrollTop;
      curDirection = curScroll > prevScroll ? 2 : 1;

      if (curDirection !== prevDirection) {
        changeHeader();
      }

      prevScroll = curScroll;
    };

    const changeHeader = () => {
      if (curDirection === 2 && curScroll > threshold) {
        header.classList.add("header--hide");
        prevDirection = curDirection;
      } else if (curDirection === 1) {
        header.classList.remove("header--hide");
        prevDirection = curDirection;
      }
    };

    window.addEventListener("scroll", checkScroll);
  });

  const menuContext = useContext(MenuContext);

  const tealiumLink = (mainTitle) => {
    if (typeof window === "undefined" || !window.utag) return;
  
    const lastSegment = location.href.replace(/\/$/, "").split("/").pop();
    const currentPath =
      location.href.includes("essential-reading")
        ? "essential-reading"
        : location.href.includes("legal-information")
        ? "legal-information"
        : lastSegment || "home";
  
    const eventDetails = {
      home: {
        pageType: "homepage",
        pageSubCategory: "Home",
        rawDataLayer: "10451v2",
        eventContent: `topnav | home page : ${mainTitle}`,
      },
      products: {
        pageType: "product information",
        pageName: "products",
        pageSubCategory: "products",
        rawDataLayer: "10451v8",
        eventContent: `topnav | products : ${mainTitle}`,
      },
      criteria: {
        pageType: "product information",
        pageName: "criteria",
        pageSubCategory: "criteria",
        rawDataLayer: "10451v12",
        eventContent: `topnav | criteria : ${mainTitle}`,
      },
      calculator: {
        pageType: "product information",
        pageName: "calculator",
        pageSubCategory: "calculator",
        rawDataLayer: "10451v18",
        eventContent: `topnav | calculators : ${mainTitle}`,
      },
      newsroom: {
        pageType: "product information",
        pageName: "newsroom",
        pageSubCategory: "newsroom",
        rawDataLayer: "10451v22",
        eventContent: `topnav | news : ${mainTitle}`,
      },
      resources: {
        pageType: "product information",
        pageName: "resources",
        pageSubCategory: "resources",
        rawDataLayer: "10451v49",
        eventContent: `topnav | resources : ${mainTitle}`,
      },
      "our-latest-service-levels": {
        pageType: "product information",
        pageName: "our latest service levels",
        pageSubCategory: "our latest service levels",
        rawDataLayer: "10451v69",
        eventContent: `topnav | our latest service levels : ${mainTitle}`,
      },
      complaints: {
        pageType: "product information",
        pageName: "complaints",
        pageSubCategory: "complaints",
        rawDataLayer: "10451v39",
        eventContent: `topnav | complaints : ${mainTitle}`,
      },
      awards: {
        pageType: "product information",
        pageName: "awards",
        pageSubCategory: "awards",
        rawDataLayer: "10451v62",
        eventContent: `topnav | awards : ${mainTitle}`,
      },
      "packaging-requirements": {
        pageType: "product information",
        pageName: "packaging requirements",
        pageSubCategory: "packaging requirements",
        rawDataLayer: "10451v73",
        eventContent: `topnav | packaging requirements : ${mainTitle}`,
      },
      "broker-support": {
        pageType: "product information",
        pageName: "broker support",
        pageSubCategory: "broker support",
        rawDataLayer: "10451v31",
        eventContent: `topnav | broker support : ${mainTitle}`,
      },
      "energy-performance-certificate": {
        pageType: "product information",
        pageName: "energy performance certificate",
        pageSubCategory: "energy performance certificate",
        rawDataLayer: "10451v88",
        eventContent: `topnav | energy performance certificate : ${mainTitle}`,
      },
      "placing-business": {
        pageType: "product information",
        pageName: "placing business",
        pageSubCategory: "placing business",
        rawDataLayer: "10451v53",
        eventContent: `topnav | placing business : ${mainTitle}`,
      },
      "essential-reading": {
        pageType: "product information",
        pageName: `newsroom:essential reading:${lastSegment}`,
        pageSubCategory: "newsroom",
        rawDataLayer: "10451v27",
        eventContent: `topnav | ${lastSegment} : ${mainTitle}`,
      },
      "legal-information": {
        pageType: "product information",
        pageName: "legal information",
        pageSubCategory: "legal information",
        rawDataLayer: "10451v58",
        eventContent: `topnav | legal information : ${mainTitle}`,
      },
      "frequently-asked-questions": {
        pageType: "product information",
        pageName: "frequently asked questions",
        pageSubCategory: "frequently asked questions",
        rawDataLayer: "10451v65",
        eventContent: `topnav | frequently asked questions : ${mainTitle}`,
      },
    };

    const config = eventDetails[currentPath];
    if (!config) {
      return;
    }

    const { eventContent, pageType, pageName = "", pageSubCategory, rawDataLayer } = config;
  
    triggerTealiumEvent({
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      eventAction: "onsite",
      eventContent,
      pageType,
      pageName,
      pageSubCategory,
      rawDataLayer,
    });
  };

  return (
    <header>
      <div className="header-wrapper header-wrapper--black" inert={menuContext.isMenuOpen ? "true" : null}>
        <div className="header-description header-description--centered wrapper">
          <div className="main-title-wrapper">Intermediaries</div>
          <p className="header-description__description">
            This website is for the use of FCA authorised mortgage <strong>intermediaries only</strong>.
          </p>
          <div className="header-description__tools">
            <div className="tool-wrapper">
              <Files />
              <Link to="/packaging-requirements" activeClassName="active">
                Packaging requirements
              </Link>
            </div>
            <div className="tool-wrapper calculator" inert={menuContext.isMenuOpen ? "true" : null}>
              <a href="/calculator" target="_blank" rel="noreferrer">
                <Calculator />
                Launch calculators
                <NewWindow />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="header-wrapper" inert={menuContext.isMenuOpen ? "true" : null}>
        <div className="header-description header-description--no-padding wrapper">
          <div className="header-description__mobile-menu">
            <MenuContext.Consumer>
              {({ isMenuOpen, toggleMenu }) => (
                <button
                  className="mobile-menu-button"
                  aria-label="Toggle navigation"
                  role="presentation"
                  tabIndex="0"
                  onClick={() => {
                    setIsMobileMenuOpen(true);
                    toggleMenu();
                  }}
                  inert={menuContext.isMenuOpen ? "true" : null}
                >
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </button>
              )}
            </MenuContext.Consumer>
          </div>
          <Link
            className="logo"
            aria-label="HSBC UK homepage"
            to="/"
            inert={menuContext.isMenuOpen ? "true" : null}
          ></Link>
          <nav className="link-container">
            {GENERAL_LINK_SCHEME.map(({ mainTitle, innerTitle, link, isLink, isBlank }) => (
              <React.Fragment key={mainTitle}>
                {isLink ? (
                  <Link className="navigation-item" to={link} onClick={() => tealiumLink(mainTitle)}>
                    <span className="main">{mainTitle}</span>
                    <span className="second">{innerTitle}</span>
                  </Link>
                ) : (
                  <Link
                    className="navigation-item"
                    to={link}
                    target={isBlank ? "_blank" : "_self"}
                    rel={isBlank ? "noreferrer" : undefined}
                  >
                    <span className="main">{mainTitle}</span>
                    <span className="second">{innerTitle}</span>
                  </Link>
                )}
              </React.Fragment>
            ))}
          </nav>
          <div className="header-description__user-action-container">
            <GeneralButton
              additionalClass="profile-btn"
              ariaLabel="Register to the website"
              onClick={() => {
                window.location.href = "https://platform.intermediaries.hsbc.co.uk/register";
              }}
            >
              <Profile /> Register
            </GeneralButton>
            <GeneralButton
              additionalClass="log-in"
              ariaLabel="Log on to the website"
              isRed
              onClick={() => {
                window.location.href = "https://platform.intermediaries.hsbc.co.uk/CSSO/LoginRequest?ReturnUrl=/login/";
              }}
            >
              Log on
            </GeneralButton>
          </div>
        </div>
      </div>
      <MobileMenu setIsOpen={setIsMobileMenuOpen} isOpen={isMobileMenuOpen} />
    </header>
  );
};

export default Header;
