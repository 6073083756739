export const SLA_DATE = {
  value: "27/01/2025",
};

export const SLA_RESIDENTAL_EMPLOYED = {
  title: `Residential employed`,
  value: `6 w/d`,
};

export const SLA_RESIDENTAL_SELF_EMPLOYED = {
  title: `Residential self-employed`,
  value: "5 w/d",
};

export const SLA_BUY_TO_LET = {
  title: `Buy to let`,
  value: "4 w/d",
};

export const SLA_ASSESSING_SUPPORTING_DOCUMENTS = {
  title: `Assessing <strong>supporting documents</strong>`,
  value: "3 w/d",
};

export const SLA_RESPONDING_BROKER_MESSAGES = {
  title: `Responding to <strong>Broker messages</strong>`,
  value: "3 w/d",
};

export const SLA_REVIEWING_APPLICATION_AMENDMENT_FORMS = {
  title: `Reviewing <strong>Application Amendment Forms</strong>`,
  value: "7 w/d",
};

export const SLA_ANSWERING_TIME = {
  title: `Call Answering Time`,
  value: "116 seconds",
};

export const SLA_MONTH = {
  value: "January",
};