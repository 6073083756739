import React from 'react';
import AnchorList from '../anchor-list/anchor-list.component';

import { FAQ_QUESTIONS_SCHEME } from '../../data/faq.data';
import GeneralAnchor from '../general-anchor/general-anchor.component';

const Faq = ({ faqList = FAQ_QUESTIONS_SCHEME }) => {

  return (
    <section className='faq'>
      <h2>
        <GeneralAnchor
          isBlank
          href='/frequently-asked-questions'
          rel='noreferrer'
          target='_self'
          isLink={false}
          noClass={true}
        >
          Frequently asked questions
        </GeneralAnchor>
      </h2>
      <AnchorList anchorItems={faqList} additionalClassName={'ul-ns'} />
    </section>
  );
};

export default Faq;